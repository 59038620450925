@tailwind base;
@tailwind components;
@tailwind utilities;

.deconnexion {
  position: absolute;
  top: 92px;
  left: 50%;
  width: 100%;
  max-width: 200px;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-bottom-color: rgb(243 244 246 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.5rem;
  display: none;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  justify-content: space-around;
  align-items: center;
}

.deconnexion-container {
  position: absolute;
  right: 0;
  top: 5px;
  width: 150px;
  color: black;
}

.custom-title {
  font-size: 2.5rem;
  line-height: 2.75rem;
  color: white;
}

.custom-header {
  align-items: center;
  /* Align items vertically in the center */
  justify-content: center;
}

.analyse-listing {
  overflow-y: scroll;
  max-height: 500px;
}

.login-header {
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  height: 200px;
}

.home-background {
  background:
    linear-gradient(rgba(71, 142, 165, 0.5), rgba(61, 110, 126, 0.4)),
    url(./assets/img/home-bg.jpg) no-repeat;
  background-size: cover;
  overflow-y: auto;
}

.wb-logo {
  max-width: 400px;
  display: flex;
  align-items: center;
  height: 120px;
}

.user-icon {
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 666666;
}

.leaflet-container {
  height: 100%;
}

.transition-pacofide {
  @apply transition-all duration-300 ease-in-out;
}

.navbar-brand {
  @apply text-xl sm:text-3xl text-white my-auto relative after:absolute after:content-[''] after:w-[6px] after:h-[6px] after:-right-2 after:bottom-2 after:rounded-full after:bg-[#FC2EB0];
}

.pacofide-nav-dropdown-menu {
  @apply absolute max-w-[400px] hidden grid-cols-2 gap-4 w-full p-2 bg-white border top-[92px] left-1/2 -translate-x-1/2 border-b-gray-100 rounded-md pointer-events-none transition-pacofide;
}

.pacofide-nav-dropdown-link-inner {
  @apply !ml-0 mt-2 text-xs text-slate-500;
}

.custom-div-icon i {
  position: absolute;
  width: 22px;
  font-size: 22px;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}

.searchInput input:focus {
  --tw-ring-color: none;
}

#menu-toggle:checked + #menu {
  display: block;
}

.mapping {
  overflow: hidden;
  width: 35%;
}

.container {
  overflow: hidden;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.countryLabel {
  border: none;
  box-shadow: none;
  background-color: transparent;
  font-weight: bold;
  font-size: 10px;
}

.filiere-text {
  height: 240px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

.leaflet-tooltip.my-labels {
  background-color: transparent;
  border: transparent;
  box-shadow: none;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-tooltip.map-labels {
  background-color: transparent;
  border: transparent;
  box-shadow: none;
}
